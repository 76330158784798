class SliderHomeKv {
  constructor() {
    this.El = document.querySelector('[data-slide-kv="el"]');
    if ( this.El !== null ) {
      this.fieldEl = this.El.querySelector('[data-slide-kv="field"]');
      this.handler = null;
      this.setup();
    }
  }
  setup() {
    this.handler = new Swiper(this.fieldEl, {
      loop: true,
      speed: 1000,
      parallax: true,
      //effect: 'fade',
      //allowTouchMove: false,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false
      },
      on: {
        beforeInit: (self) => {
          [...self.$wrapperEl[0].children].forEach((item) => {
            item.innerHTML = '<div class="swiper-slide-inner" data-swiper-parallax-x="100%">' + item.innerHTML + '</div>';
          });
        },
        init: (self) => {
          this.autoPlayObserve(self);
        }
      }
    });
  }
  autoPlayObserve(trgt) {
    trgt.autoplay.stop();
    if ( typeof ScrollTrigger.getById('slideKvObserve') === 'undefined' ) {
      ScrollTrigger.create({
        id: 'slideKvObserve',
        trigger: trgt.$el[0],
        start: 'top bottom',
        //markers: true,
        onEnter: () => {
          if ( trgt !== null ) trgt.autoplay.start();
        },
        onEnterBack: () => {
          if ( trgt !== null ) trgt.autoplay.start();
        },
        onLeave: () => {
          if ( trgt !== null ) trgt.autoplay.stop();
        },
        onLeaveBack: () => {
          if ( trgt !== null ) trgt.autoplay.stop();
        }
      });
    }
  }
}
