class SliderHomeFeature {
  constructor() {
    this.El = document.querySelector('[data-slide-feature="el"]');
    if ( this.El !== null ) {
      this.fieldEl    = this.El.querySelector('[data-slide-feature="field"]');
      this.prevEl     = this.El.querySelector('[data-slide-feature="prev"]');
      this.nextEl     = this.El.querySelector('[data-slide-feature="next"]');
      this.progressEl = this.El.querySelector('[data-slide-feature="progress"]');
      this.handler = undefined;
      this.setup();
      this.resizeEvent();
    }
  }
  setup() {
    if ( isMobile() ) {
      this.handler = new Swiper(this.fieldEl, {
        slidesPerView: 'auto',
        loop: true,
        speed: 1000,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false
        },
        navigation: {
          prevEl: this.prevEl,
          nextEl: this.nextEl
        },
        pagination: {
          el: this.progressEl,
          type: 'progressbar'
        },
        on: {
          init: (self) => {
            this.autoPlayObserve(self);
          }
        }
      });
    } else {
      if ( typeof this.handler !== 'undefined' ) {
        this.handler.destroy(true, true);
        this.handler = undefined;
        ScrollTrigger.getById('slideFeatureObserve').kill();
      }
    }
  }
  autoPlayObserve(trgt) {
    trgt.autoplay.stop();
    if ( typeof ScrollTrigger.getById('slideFeatureObserve') === 'undefined' ) {
      ScrollTrigger.create({
        id: 'slideFeatureObserve',
        trigger: trgt.$el[0],
        start: 'top 90%',
        //markers: true,
        onEnter: () => {
          if ( trgt !== null ) trgt.autoplay.start();
        },
        onEnterBack: () => {
          if ( trgt !== null ) trgt.autoplay.start();
        },
        onLeave: () => {
          if ( trgt !== null ) trgt.autoplay.stop();
        },
        onLeaveBack: () => {
          if ( trgt !== null ) trgt.autoplay.stop();
        }
      });
    }
  }
  resizeEvent() {
    let vw = window.innerWidth;
    window.addEventListener('resize', () => {
      if (vw === window.innerWidth) return;
      vw = window.innerWidth;
      this.setup();
    });
  }
}
