class Modal {
  constructor() {
    this.triggerEls = document.querySelectorAll('[data-modal="trigger"]');
    if ( this.triggerEls.length ) {
      this.El         = document.querySelector('[data-modal="el"]');
      this.backdropEl = this.El.querySelector('[data-modal="backdrop"]');
      this.wrapperEl  = this.El.querySelector('[data-modal="wrapper"]');
      this.contentEl  = this.wrapperEl.querySelector('[data-modal="content"]');
      this.bodyEl     = this.contentEl.querySelector('[data-modal="body"]');
      this.closeEls   = this.contentEl.querySelectorAll('[data-modal="close"]');
      this.clickEvent();
    }
  }
  clickEvent() {
    this.triggerEls.forEach((item) => {
      item.addEventListener('click', (e) => {
        this.preOpen(item);
        this.setNode(item);
        e.preventDefault();
      });
    });

    this.closeEls.forEach((item) => {
      item.addEventListener('click', () => {
        this.close();
      });
    });
    this.wrapperEl.addEventListener('click', (e) => {
      if ( !e.target.closest(`.${this.contentEl.className}`) ) {
        this.close();
      }
    });
  }
  setNode(trgt) {
    if ( trgt.dataset.modalVideo ) {
      this.bodyEl.innerHTML = ''
        + '<div class="c-youtube">'
        + '<iframe width="560" height="315" src="https://www.youtube.com/embed/' + trgt.dataset.modalVideo + '?autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>'
        + '</div>'
      + '';
      this.checkOption(trgt);
      this.open(trgt);
    } else if ( trgt.dataset.modalType === 'image' ) {
      this.bodyEl.innerHTML = ''
        + '<img src="' + trgt.getAttribute('href') + '">'
      + '';
      this.checkOption(trgt);
      this.open(trgt);
    } else {
      let target = trgt.getAttribute('href') || null;
      if (target === null) {
        console.log('Set ID in href');
        return;
      }
      let origin = document.getElementById(target.replace('#', ''));
      if (origin === null) {
        console.log('Not found ID element');
        return;
      }
      let clone = this.removeId(origin);
      this.bodyEl.appendChild(clone);
      this.checkOption(trgt, clone);
      this.open(trgt);
    }
  }
  removeId(trgt) {
    let clone = trgt.cloneNode(true); clone.removeAttribute('id');
    //let innerNode = clone.getElementsByTagName('*');
    //for( let i = 0; i < innerNode.length; i++ ) {
    //  innerNode[i].removeAttribute('id');
    //}
    return clone;
  }
  checkOption(trgt, clone) {
    // this.Elへ属性付与した場合はclose時deleteする => this.clearProps()

    if ( trgt.dataset.modalWidth ) {
      this.contentEl.style.maxWidth = `${trgt.dataset.modalWidth}px`;
    }

    if ( trgt.dataset.modalVideo || trgt.dataset.modalImage ) {
      this.El.dataset.costom = 'noradius';
    }

    if ( trgt.dataset.modalMethod ) {
      let data = trgt.dataset.modalMethod.split(',');
      for ( let i = 0; i < data.length; i++ ) {

        if ( data[i].trim() == 'gallery' ) {
          let thumbs = clone.querySelectorAll('[data-modal="gallery"]');
          thumbs.forEach((item) => {
            item.addEventListener('click', () => {
              let idx = Array.prototype.indexOf.call(thumbs, item);
              sliderProduct['handler'].slideTo(idx+1);
              this.close();
            });
          });
        }

        //...add any method
        //if ( data[i].trim() == 'xxxxxxxxx' ) {
        //  let xxxxxxx = new xxxxxxx(clone);
        //}
      }
    }
  }
  preOpen(trgt) {
    gsap.to(this.backdropEl, {
      opacity: 1,
      duration: .25,
      ease: Linear.easeNone,
      onStart: () => {
        this.El.showModal();
        this.fixedWindowOn();
      }
    });
  }
  open(trgt) {
    gsap.to(this.contentEl, {
      scale: 1,
      opacity: 1,
      duration: .375,
      ease: Cubic.easeOut,
      onStart: () => {
        this.El.scrollTo(0, 0);
      }
    });
  }
  close() {
    gsap.to(this.contentEl, {
      opacity: 0,
      duration: .125,
      ease: Linear.easeNone,
      onStart: () => {
        gsap.to(this.backdropEl, {
          opacity: 0,
          duration: .125,
          ease: Linear.easeNone
        });
      },
      onComplete: () => {
        this.El.close();
        this.fixedWindowOff();
        this.clearProps();
        this.bodyEl.children[0].remove();
      }
    });
  }
  fixedWindowOn() {
    let scrollY = window.scrollY;
    Object.assign(document.body.style, {
      //overflow: 'hidden', position: 'fixed', top: (scrollY * -1) + 'px'
      position: 'fixed', top: (scrollY * -1) + 'px'
    });
  }
  fixedWindowOff() {
    let scrollY = parseInt( document.defaultView.getComputedStyle(document.body, null).top );
    Object.assign(document.body.style, {
      //overflow: null, position: null, top: null
      position: null, top: null
    });
    window.scrollTo(0, scrollY * -1);
  }
  clearProps() {
    delete this.El.dataset.costom;
    gsap.set([this.El, this.backdropEl, this.contentEl], {clearProps: true});
  }
}
