class PublisherBtn {
  constructor() {


    // To Do リサイズ時の挙動


    this.El = document.querySelector('[data-publish="el"]');
    if ( this.El !== null ) {
      this.triggerEl = this.El.querySelector('[data-publish="trigger"]');
      this.targetEl = this.El.querySelector('[data-publish="target"]');
      this.storeEl = this.El.querySelector('[data-publish="store"]');
      this.shortertextEl  = this.storeEl.querySelector('[data-publish="text"]');
      this.shortertextW = null;
      this.loadEvent();
      this.resizeEvent();
      this.clickEvent();
    }
  }
  loadEvent() {
    this.getShorterWidth();
  }
  resizeEvent() {
    let vw = window.innerWidth;
    window.addEventListener('resize', () => {
      if (vw === window.innerWidth) return;
      vw = window.innerWidth;
        if( !isMobile() ) {
          this.clearProps();
        } else {
          if ( this.El.dataset.state === undefined ) this.getShorterWidth();
        }
    });
  }
  clickEvent() {
    this.triggerEl.addEventListener('click', () => {
      if ( this.El.dataset.state === undefined ) {
        this.open();
      } else {
        this.close();
      }
    });
  }
  getShorterWidth() {
    this.shortertextW = this.storeEl.clientWidth;
  }
  clearProps() {
    delete this.El.dataset.state;
    gsap.set([this.targetEl, this.storeEl, this.shortertextEl], {clearProps: true});
  }
  open() {
    gsap.to(this.targetEl, {
      width: 'auto',
      opacity: 1,
      duration: .5,
      ease: Power2.easeInOut,
      onStart: () => {
        gsap.to(this.storeEl, {
          width: this.storeEl.clientHeight,
          opacity: 1,
          duration: .5,
          ease: Power2.easeInOut
        });
        gsap.to(this.shortertextEl, {
          width: 0,
          opacity: 0,
          duration: .375,
          ease: Power2.easeInOut,
          onComplete: () => {
            this.shortertextEl.style.display = 'none';
          }
        });
      },
      onComplete: () => {
        this.El.dataset.state = 'open';
      }
    });
  }
  close() {
    gsap.to(this.targetEl, {
      width: 0,
      opacity: 0,
      duration: .5,
      ease: Power2.easeInOut,
      clearProps: true,
      onStart: () => {
        gsap.to(this.storeEl, {
          width: this.shortertextW,
          opacity: 1,
          duration: .5,
          ease: Power2.easeInOut
        });
        gsap.to(this.shortertextEl, {
          width: 'auto',
          opacity: 1,
          duration: .25,
          ease: Power2.easeOut,
          onStart: () => {
            this.shortertextEl.style.display = 'block';
          }
        });
     },
      onComplete: () => {
        delete this.El.dataset.state;
        this.getShorterWidth();
      }
    });
  }


}
