class Variant {
  constructor() {
    this.El = document.querySelector('[data-variant="el"]');
    if ( this.El !== null ) {
      this.triggerEls = [...this.El.children];
      this.priceEl    = this.El.nextElementSibling.querySelector('[data-variant="price"]');
      this.storeEl    = this.El.nextElementSibling.nextElementSibling.querySelector('[data-variant="store"]');
      this.amazonEl   = this.El.nextElementSibling.nextElementSibling.querySelector('[data-variant="amazon"]');
      this.rakutenEl  = this.El.nextElementSibling.nextElementSibling.querySelector('[data-variant="rakuten"]');
      this.yahooEl    = this.El.nextElementSibling.nextElementSibling.querySelector('[data-variant="yahoo"]');
      this.auEl    = this.El.nextElementSibling.nextElementSibling.querySelector('[data-variant="au"]');
      //this.loadEvent(); // to PHP
      this.clickEvent();
    }
  }
  loadEvent() {
    this.setData(this.triggerEls[0]);
  }
  clickEvent() {
    this.triggerEls.forEach((item) => {
      item.addEventListener('click', () => {
        this.triggerEls.forEach((item2) => { delete item2.dataset.state });
        item.dataset.state = 'active';
        this.setData(item);
      });
    });
  }
  setData(item) {
    this.priceEl.textContent = parseInt(item.dataset.price).toLocaleString();

    if ( item.dataset.urlStore?.trim() ) {
      this.storeEl.setAttribute('href', item.dataset.urlStore);
      delete this.storeEl.dataset.state;
    } else {
      this.storeEl.removeAttribute('href');
      this.storeEl.dataset.state = 'disable'
    }

    if ( item.dataset.urlAmazon?.trim() ) {
      this.amazonEl.setAttribute('href', item.dataset.urlAmazon);
      delete this.amazonEl.dataset.state;
    } else {
      this.amazonEl.removeAttribute('href');
      this.amazonEl.dataset.state = 'disable'
    }

    if ( item.dataset.urlRakuten?.trim() ) {
      this.rakutenEl.setAttribute('href', item.dataset.urlRakuten);
      delete this.rakutenEl.dataset.state;
    } else {
      this.rakutenEl.removeAttribute('href');
      this.rakutenEl.dataset.state = 'disable'
    }

    if ( item.dataset.urlYahoo?.trim() ) {
      this.yahooEl.setAttribute('href', item.dataset.urlYahoo);
      delete this.yahooEl.dataset.state;
    } else {
      this.yahooEl.removeAttribute('href');
      this.yahooEl.dataset.state = 'disable'
    }

    if ( item.dataset.urlAu?.trim() ) {
      this.auEl.setAttribute('href', item.dataset.urlAu);
      delete this.auEl.dataset.state;
    } else {
      this.auEl.removeAttribute('href');
      this.auEl.dataset.state = 'disable'
    }

  }
}
