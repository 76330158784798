class AnchorLink {
  constructor(el, opt) {
    this.triggerEls = document.querySelectorAll('a[href^="#"]');
    if ( this.triggerEls.length ) {
      this.ignore = ['modal']; // data-*****
      this.gap    = {pc: 175, sp: 120}
      this.clickEvent();
    }
  }
  clickEvent() {
    this.triggerEls.forEach( (item) => {
      for ( let i = 0; i < this.ignore.length; i++ ) if ( this.ignore[i] in item.dataset ) return;
      item.addEventListener('click', (e) => {
        this.smooth(item);
        e.preventDefault();
      });
    });
  }
  smooth(trigger) {
    let target = document.getElementById( trigger.getAttribute('href').replace('#', '') );
    if (target === null) {
      console.log('Smooth [Not found ID element]\nPlease specify destination ID');
      return;
    }
    let pos = isMobile() ? window.pageYOffset + target.getBoundingClientRect().top - this.gap.sp : window.pageYOffset + target.getBoundingClientRect().top - this.gap.pc;
    gsap.to(window, .75, {
      scrollTo: {
        y: pos,
        autoKill: false
      },
      delay: 0,
      ease: Cubic.easeInOut
    });
  }
}
