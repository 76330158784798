class SliderRepair {
  constructor() {
    this.Els = document.querySelectorAll('[data-slide-repair="el"]');
    if ( this.Els.length ) {
      this.fieldAttr     = '[data-slide-repair="field"]';
      this.prevAttr      = '[data-slide-repair="prev"]';
      this.nextAttr      = '[data-slide-repair="next"]';
      this.pagerAttr     = '[data-slide-repair="pager"]';
      this.handler   = [];
      this.setup();
    }
  }
  setup() {
    this.Els.forEach((item, index) => {
      let fieldEl     = item.querySelector(this.fieldAttr);
      let prevEl      = item.querySelector(this.prevAttr);
      let nextEl      = item.querySelector(this.nextAttr);
      let pagerEl     = item.querySelector(this.pagerAttr);

      this.handler[index] = new Swiper(fieldEl, {
        loop: true,
        speed: 1000,
        navigation: {
          prevEl: prevEl,
          nextEl: nextEl
        },
        pagination: {
          el: pagerEl,
          type: 'bullets',
          horizontalClass: 'swiper-add-pager',
          bulletClass: 'swiper-add-dot',
          bulletActiveClass: '-active',
          clickable: true
        }
      });
    });
  }
}
