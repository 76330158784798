// globals
class HeaderEvents {
  constructor() {
    this.header = document.querySelector('[data-global="header"]');
    if ( this.header !== null ) {
      this.nav = document.querySelector('[data-global="nav"]');
      this.navMain = this.nav.querySelector('[data-global="nav-main"]');
      this.collapse = this.nav.querySelector('[data-global="collapse"]');
      this.collapseBtns = [];
      this.collapseBtns.push( this.header.querySelector('[data-global="collapse-btn"]'), this.nav.querySelector('[data-global="collapse-btn"]') );
      this.mobileFlg = null;
      this.loadEvent();
      this.resizeEvent();
      this.clickEvent();
    }
  }
  loadEvent() {
    this.setMobileFlg();
    this.setCollapseHeight();
    this.setNavMain();
  }
  resizeEvent() {
    let vw = window.innerWidth;
    window.addEventListener('resize', () => {
      if (vw === window.innerWidth) return;
      vw = window.innerWidth;
        if( this.mobileFlg !== isMobile() && !isMobile() && document.documentElement.dataset.nav === 'open' ) {
          this.collapseClose();
          this.fixedWindowOff();
        }
        this.setCollapseHeight();
        this.setMobileFlg();
    });
  }
  clickEvent() {
    this.collapseBtns.forEach((item) => {
      item.addEventListener('click', (e) => {
        if ( document.documentElement.dataset.nav === undefined ) {
          this.collapseOpen();
        } else {
          this.collapseClose();
        }
      });
    });
  }
  setMobileFlg() {
    this.mobileFlg = isMobile();
  }
  setCollapseHeight() {
    this.header.dataset.height = this.header.offsetHeight;
    this.navMain.dataset.height = this.navMain.offsetHeight;
  }
  setNavMain() {
    [...this.navMain.children].forEach((item) => {
      if ( item.nodeName === 'A' && location.href.startsWith(item.href) ) {
        item.dataset.state = 'current';
        let rect = item.getBoundingClientRect(), baf = window.innerWidth * .01 * 5;
        this.navMain.scrollLeft = rect.left - baf;
      }
    });
  }
  collapseOpen() {
    gsap.to(this.collapse, {
      height: isMobile() ? this.collapseOpenExt() : 'auto',
      //opacity: 1,
      duration: .5,
      ease: Power3.easeOut,
      onStart: () => {
        if( isMobile() ) this.fixedWindowOn();
        document.documentElement.dataset.nav = 'open';
      },
      onComplete: () => {
        //if( isMobile() ) this.collapseOpenExt();
      }
    });
  }
  collapseOpenExt() {
    let hh = parseInt(this.header.dataset.height), nh = parseInt(this.navMain.dataset.height);
    if ( hh < window.scrollY ) {
      Object.assign(this.nav.style, {
        position: 'fixed', top: 0
      });
      return (window.innerHeight - nh);
    } else {
      return (window.innerHeight + window.scrollY - hh - nh);
    }
  }
  collapseClose() {
    gsap.to(this.collapse, {
      height: 0,
      //opacity: 0,
      duration: .5,
      ease: Power3.easeOut,
      clearProps: true,
      onStart: () => {
        if( isMobile() ) this.fixedWindowOff();
        delete document.documentElement.dataset.nav;
      }
    });
  }
  fixedWindowOn() {
    let scrollY = window.scrollY;
    Object.assign(document.body.style, {
      //overflow: 'hidden', position: 'fixed', top: (scrollY * -1) + 'px'
      position: 'fixed', top: (scrollY * -1) + 'px'
    });
  }
  fixedWindowOff() {
    let scrollY = parseInt( document.defaultView.getComputedStyle(document.body, null).top );
    Object.assign(document.body.style, {
      //overflow: null, position: null, top: null
      position: null, top: null
    });
    Object.assign(this.nav.style, {
      position: null, top: null
    });
    window.scrollTo(0, scrollY * -1);
  }
}

class FooterEvents {
  constructor() {
    this.footer = document.querySelector('[data-global="footer"]');
    if ( this.footer !== null ) {
      this.gallery = this.footer.querySelector('[data-global="gallery"]');
      this.galleryPlayObserve();
    }
  }
  galleryPlayObserve() {
    if ( typeof ScrollTrigger.getById('galleryObserve') === 'undefined' ) {
      ScrollTrigger.create({
        id: 'galleryObserve',
        trigger: this.gallery,
        start: 'top bottom',
        //markers: true,
        onEnter: () => {
          this.gallery.dataset.state = 'play';
        },
        onEnterBack: () => {
          this.gallery.dataset.state = 'play';
        },
        onLeave: () => {
          delete this.gallery.dataset.state;
        },
        onLeaveBack: () => {
          delete this.gallery.dataset.state;
        }
      });
    }
  }
}
