class SliderProduct {
  constructor() {
    this.El = document.querySelector('[data-slide-gallery="el"]');
    if ( this.El !== null ) {
      this.fieldEl = this.El.querySelector('[data-slide-gallery="field"]');
      this.prevEl  = this.El.querySelector('[data-slide-gallery="prev"]');
      this.nextEl  = this.El.querySelector('[data-slide-gallery="next"]');
      this.fracEl  = this.El.querySelector('[data-slide-gallery="frac"]');
      this.capEl   = this.El.querySelector('[data-slide-gallery="cap"]');
      this.handler = null;
      this.setup();
    }
  }
  setup() {
    this.handler = new Swiper(this.fieldEl, {
      loop: true,
      speed: 750,
      pagination: {
        el: this.fracEl,
        type: 'fraction'
      },
      navigation: {
        prevEl: this.prevEl,
        nextEl: this.nextEl
      },
      on: {
        slideChangeTransitionStart: () => {
          gsap.to(this.capEl, {
            autoAlpha: 0,
            duration: .125,
            ease: Linear.easeNone
          });
        },
        slideChangeTransitionEnd: (swiper) => {
          if ( swiper.slides[swiper.activeIndex].dataset.text !== undefined ) {
            gsap.to(this.capEl, {
              autoAlpha: 1,
              duration: .25,
              ease: Linear.easeNone,
              onStart: () => {
                this.capEl.textContent = swiper.slides[swiper.activeIndex].dataset.text;
              }
            });
          }
        }
      }
    });
  }
}
