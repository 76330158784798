// globals
class HeaderEvents {
  constructor() {
    this.header = document.querySelector('[data-global="header"]');
    if ( this.header !== null ) {
      this.nav = document.querySelector('[data-global="nav"]');
      this.navMain = this.nav.querySelector('[data-global="nav-main"]');
      this.collapse = this.nav.querySelector('[data-global="collapse"]');
      this.collapseBtns = [];
      this.collapseBtns.push( this.header.querySelector('[data-global="collapse-btn"]'), this.nav.querySelector('[data-global="collapse-btn"]') );
      this.mobileFlg = null;
      this.loadEvent();
      this.resizeEvent();
      this.clickEvent();
    }
  }
  loadEvent() {
    this.setMobileFlg();
    this.setCollapseHeight();
    this.setNavMain();
  }
  resizeEvent() {
    let vw = window.innerWidth;
    window.addEventListener('resize', () => {
      if (vw === window.innerWidth) return;
      vw = window.innerWidth;
        if( this.mobileFlg !== isMobile() && !isMobile() && document.documentElement.dataset.nav === 'open' ) {
          this.collapseClose();
          this.fixedWindowOff();
        }
        this.setCollapseHeight();
        this.setMobileFlg();
    });
  }
  clickEvent() {
    this.collapseBtns.forEach((item) => {
      item.addEventListener('click', (e) => {
        if ( document.documentElement.dataset.nav === undefined ) {
          this.collapseOpen();
        } else {
          this.collapseClose();
        }
      });
    });
  }
  setMobileFlg() {
    this.mobileFlg = isMobile();
  }
  setCollapseHeight() {
    this.header.dataset.height = this.header.offsetHeight;
    this.navMain.dataset.height = this.navMain.offsetHeight;
  }
  setNavMain() {
    [...this.navMain.children].forEach((item) => {
      if ( item.nodeName === 'A' && location.href.startsWith(item.href) ) {
        item.dataset.state = 'current';
        let rect = item.getBoundingClientRect(), baf = window.innerWidth * .01 * 5;
        this.navMain.scrollLeft = rect.left - baf;
      }
    });
  }
  collapseOpen() {
    gsap.to(this.collapse, {
      height: isMobile() ? this.collapseOpenExt() : 'auto',
      //opacity: 1,
      duration: .5,
      ease: Power3.easeOut,
      onStart: () => {
        if( isMobile() ) this.fixedWindowOn();
        document.documentElement.dataset.nav = 'open';
      },
      onComplete: () => {
        //if( isMobile() ) this.collapseOpenExt();
      }
    });
  }
  collapseOpenExt() {
    let hh = parseInt(this.header.dataset.height), nh = parseInt(this.navMain.dataset.height);
    if ( hh < window.scrollY ) {
      Object.assign(this.nav.style, {
        position: 'fixed', top: 0
      });
      return (window.innerHeight - nh);
    } else {
      return (window.innerHeight + window.scrollY - hh - nh);
    }
  }
  collapseClose() {
    gsap.to(this.collapse, {
      height: 0,
      //opacity: 0,
      duration: .5,
      ease: Power3.easeOut,
      clearProps: true,
      onStart: () => {
        if( isMobile() ) this.fixedWindowOff();
        delete document.documentElement.dataset.nav;
      }
    });
  }
  fixedWindowOn() {
    let scrollY = window.scrollY;
    Object.assign(document.body.style, {
      //overflow: 'hidden', position: 'fixed', top: (scrollY * -1) + 'px'
      position: 'fixed', top: (scrollY * -1) + 'px'
    });
  }
  fixedWindowOff() {
    let scrollY = parseInt( document.defaultView.getComputedStyle(document.body, null).top );
    Object.assign(document.body.style, {
      //overflow: null, position: null, top: null
      position: null, top: null
    });
    Object.assign(this.nav.style, {
      position: null, top: null
    });
    window.scrollTo(0, scrollY * -1);
  }
}

class FooterEvents {
  constructor() {
    this.footer = document.querySelector('[data-global="footer"]');
    if ( this.footer !== null ) {
      this.gallery = this.footer.querySelector('[data-global="gallery"]');
      this.galleryPlayObserve();
    }
  }
  galleryPlayObserve() {
    if ( typeof ScrollTrigger.getById('galleryObserve') === 'undefined' ) {
      ScrollTrigger.create({
        id: 'galleryObserve',
        trigger: this.gallery,
        start: 'top bottom',
        //markers: true,
        onEnter: () => {
          this.gallery.dataset.state = 'play';
        },
        onEnterBack: () => {
          this.gallery.dataset.state = 'play';
        },
        onLeave: () => {
          delete this.gallery.dataset.state;
        },
        onLeaveBack: () => {
          delete this.gallery.dataset.state;
        }
      });
    }
  }
}

class Modal {
  constructor() {
    this.triggerEls = document.querySelectorAll('[data-modal="trigger"]');
    if ( this.triggerEls.length ) {
      this.El         = document.querySelector('[data-modal="el"]');
      this.backdropEl = this.El.querySelector('[data-modal="backdrop"]');
      this.wrapperEl  = this.El.querySelector('[data-modal="wrapper"]');
      this.contentEl  = this.wrapperEl.querySelector('[data-modal="content"]');
      this.bodyEl     = this.contentEl.querySelector('[data-modal="body"]');
      this.closeEls   = this.contentEl.querySelectorAll('[data-modal="close"]');
      this.clickEvent();
    }
  }
  clickEvent() {
    this.triggerEls.forEach((item) => {
      item.addEventListener('click', (e) => {
        this.preOpen(item);
        this.setNode(item);
        e.preventDefault();
      });
    });

    this.closeEls.forEach((item) => {
      item.addEventListener('click', () => {
        this.close();
      });
    });
    this.wrapperEl.addEventListener('click', (e) => {
      if ( !e.target.closest(`.${this.contentEl.className}`) ) {
        this.close();
      }
    });
  }
  setNode(trgt) {
    if ( trgt.dataset.modalVideo ) {
      this.bodyEl.innerHTML = ''
        + '<div class="c-youtube">'
        + '<iframe width="560" height="315" src="https://www.youtube.com/embed/' + trgt.dataset.modalVideo + '?autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>'
        + '</div>'
      + '';
      this.checkOption(trgt);
      this.open(trgt);
    } else if ( trgt.dataset.modalType === 'image' ) {
      this.bodyEl.innerHTML = ''
        + '<img src="' + trgt.getAttribute('href') + '">'
      + '';
      this.checkOption(trgt);
      this.open(trgt);
    } else {
      let target = trgt.getAttribute('href') || null;
      if (target === null) {
        console.log('Set ID in href');
        return;
      }
      let origin = document.getElementById(target.replace('#', ''));
      if (origin === null) {
        console.log('Not found ID element');
        return;
      }
      let clone = this.removeId(origin);
      this.bodyEl.appendChild(clone);
      this.checkOption(trgt, clone);
      this.open(trgt);
    }
  }
  removeId(trgt) {
    let clone = trgt.cloneNode(true); clone.removeAttribute('id');
    //let innerNode = clone.getElementsByTagName('*');
    //for( let i = 0; i < innerNode.length; i++ ) {
    //  innerNode[i].removeAttribute('id');
    //}
    return clone;
  }
  checkOption(trgt, clone) {
    // this.Elへ属性付与した場合はclose時deleteする => this.clearProps()

    if ( trgt.dataset.modalWidth ) {
      this.contentEl.style.maxWidth = `${trgt.dataset.modalWidth}px`;
    }

    if ( trgt.dataset.modalVideo || trgt.dataset.modalImage ) {
      this.El.dataset.costom = 'noradius';
    }

    if ( trgt.dataset.modalMethod ) {
      let data = trgt.dataset.modalMethod.split(',');
      for ( let i = 0; i < data.length; i++ ) {

        if ( data[i].trim() == 'gallery' ) {
          let thumbs = clone.querySelectorAll('[data-modal="gallery"]');
          thumbs.forEach((item) => {
            item.addEventListener('click', () => {
              let idx = Array.prototype.indexOf.call(thumbs, item);
              sliderProduct['handler'].slideTo(idx+1);
              this.close();
            });
          });
        }

        //...add any method
        //if ( data[i].trim() == 'xxxxxxxxx' ) {
        //  let xxxxxxx = new xxxxxxx(clone);
        //}
      }
    }
  }
  preOpen(trgt) {
    gsap.to(this.backdropEl, {
      opacity: 1,
      duration: .25,
      ease: Linear.easeNone,
      onStart: () => {
        this.El.showModal();
        this.fixedWindowOn();
      }
    });
  }
  open(trgt) {
    gsap.to(this.contentEl, {
      scale: 1,
      opacity: 1,
      duration: .375,
      ease: Cubic.easeOut,
      onStart: () => {
        this.El.scrollTo(0, 0);
      }
    });
  }
  close() {
    gsap.to(this.contentEl, {
      opacity: 0,
      duration: .125,
      ease: Linear.easeNone,
      onStart: () => {
        gsap.to(this.backdropEl, {
          opacity: 0,
          duration: .125,
          ease: Linear.easeNone
        });
      },
      onComplete: () => {
        this.El.close();
        this.fixedWindowOff();
        this.clearProps();
        this.bodyEl.children[0].remove();
      }
    });
  }
  fixedWindowOn() {
    let scrollY = window.scrollY;
    Object.assign(document.body.style, {
      //overflow: 'hidden', position: 'fixed', top: (scrollY * -1) + 'px'
      position: 'fixed', top: (scrollY * -1) + 'px'
    });
  }
  fixedWindowOff() {
    let scrollY = parseInt( document.defaultView.getComputedStyle(document.body, null).top );
    Object.assign(document.body.style, {
      //overflow: null, position: null, top: null
      position: null, top: null
    });
    window.scrollTo(0, scrollY * -1);
  }
  clearProps() {
    delete this.El.dataset.costom;
    gsap.set([this.El, this.backdropEl, this.contentEl], {clearProps: true});
  }
}

class Collapse {
  constructor() {
    this.triggerEls = document.querySelectorAll('[data-collapse="trigger"]');
    if ( this.triggerEls.length ) {
      this.clickEvent();
    }
  }
  clickEvent() {
    this.triggerEls.forEach((item) => {
      item.addEventListener('click', (e) => {
        this.slideCollapse(item);
        e.preventDefault();
      });
    });
  }
  slideCollapse(triggerEl) {
    let targetEl = (triggerEl.getAttribute('href') === null) ? triggerEl.nextElementSibling : document.querySelector(triggerEl.getAttribute('href'));
    if ( triggerEl.dataset.state === undefined ) {
      gsap.to(targetEl, {
        height: 'auto',
        opacity: 1,
        duration: .5,
        ease: Power2.easeInOut,
        onStart: () => {
          triggerEl.dataset.state = 'open';
        }
      });
    } else {
      gsap.to(targetEl, {
        height: 0,
        opacity: 0,
        duration: .5,
        ease: Power2.easeInOut,
        clearProps: true,
        onStart: () => {
          delete triggerEl.dataset.state;
        }
      });
    }
  }
}

class CollapseHistory {
  constructor() {
    this.triggerEl = document.querySelector('[data-collapse="history"]');
    if ( this.triggerEl !== null ) {
      this.targetEl  = this.triggerEl.previousElementSibling;
      this.defHeight = this.targetEl.clientHeight;
      this.defText   = this.triggerEl.textContent;
      this.clickEvent();
    }
  }
  clickEvent() {
    this.triggerEl.addEventListener('click', (e) => {
      this.slideCollapse();
      e.preventDefault();
    });
  }
  slideCollapse() {
    if ( this.targetEl.dataset.state === undefined ) {
      gsap.to(this.targetEl, {
        height: 'auto',
        duration: .5,
        ease: Power2.easeInOut,
        onStart: () => {
          this.defHeight = parseInt(getComputedStyle(this.targetEl).height);
          this.triggerEl.textContent = 'CLOSE';
          this.targetEl.dataset.state = 'open';
        }
      });
    } else {
      gsap.to(this.targetEl, {
        height: this.defHeight,
        duration: .5,
        ease: Power2.easeInOut,
        clearProps: true,
        onStart: () => {
          this.triggerEl.textContent = this.defText;
          delete this.targetEl.dataset.state;
        }
      });
    }
  }

}

class PublisherBtn {
  constructor() {


    // To Do リサイズ時の挙動


    this.El = document.querySelector('[data-publish="el"]');
    if ( this.El !== null ) {
      this.triggerEl = this.El.querySelector('[data-publish="trigger"]');
      this.targetEl = this.El.querySelector('[data-publish="target"]');
      this.storeEl = this.El.querySelector('[data-publish="store"]');
      this.shortertextEl  = this.storeEl.querySelector('[data-publish="text"]');
      this.shortertextW = null;
      this.loadEvent();
      this.resizeEvent();
      this.clickEvent();
    }
  }
  loadEvent() {
    this.getShorterWidth();
  }
  resizeEvent() {
    let vw = window.innerWidth;
    window.addEventListener('resize', () => {
      if (vw === window.innerWidth) return;
      vw = window.innerWidth;
        if( !isMobile() ) {
          this.clearProps();
        } else {
          if ( this.El.dataset.state === undefined ) this.getShorterWidth();
        }
    });
  }
  clickEvent() {
    this.triggerEl.addEventListener('click', () => {
      if ( this.El.dataset.state === undefined ) {
        this.open();
      } else {
        this.close();
      }
    });
  }
  getShorterWidth() {
    this.shortertextW = this.storeEl.clientWidth;
  }
  clearProps() {
    delete this.El.dataset.state;
    gsap.set([this.targetEl, this.storeEl, this.shortertextEl], {clearProps: true});
  }
  open() {
    gsap.to(this.targetEl, {
      width: 'auto',
      opacity: 1,
      duration: .5,
      ease: Power2.easeInOut,
      onStart: () => {
        gsap.to(this.storeEl, {
          width: this.storeEl.clientHeight,
          opacity: 1,
          duration: .5,
          ease: Power2.easeInOut
        });
        gsap.to(this.shortertextEl, {
          width: 0,
          opacity: 0,
          duration: .375,
          ease: Power2.easeInOut,
          onComplete: () => {
            this.shortertextEl.style.display = 'none';
          }
        });
      },
      onComplete: () => {
        this.El.dataset.state = 'open';
      }
    });
  }
  close() {
    gsap.to(this.targetEl, {
      width: 0,
      opacity: 0,
      duration: .5,
      ease: Power2.easeInOut,
      clearProps: true,
      onStart: () => {
        gsap.to(this.storeEl, {
          width: this.shortertextW,
          opacity: 1,
          duration: .5,
          ease: Power2.easeInOut
        });
        gsap.to(this.shortertextEl, {
          width: 'auto',
          opacity: 1,
          duration: .25,
          ease: Power2.easeOut,
          onStart: () => {
            this.shortertextEl.style.display = 'block';
          }
        });
     },
      onComplete: () => {
        delete this.El.dataset.state;
        this.getShorterWidth();
      }
    });
  }


}

class Variant {
  constructor() {
    this.El = document.querySelector('[data-variant="el"]');
    if ( this.El !== null ) {
      this.triggerEls = [...this.El.children];
      this.priceEl    = this.El.nextElementSibling.querySelector('[data-variant="price"]');
      this.storeEl    = this.El.nextElementSibling.nextElementSibling.querySelector('[data-variant="store"]');
      this.amazonEl   = this.El.nextElementSibling.nextElementSibling.querySelector('[data-variant="amazon"]');
      this.rakutenEl  = this.El.nextElementSibling.nextElementSibling.querySelector('[data-variant="rakuten"]');
      this.yahooEl    = this.El.nextElementSibling.nextElementSibling.querySelector('[data-variant="yahoo"]');
      this.auEl    = this.El.nextElementSibling.nextElementSibling.querySelector('[data-variant="au"]');
      //this.loadEvent(); // to PHP
      this.clickEvent();
    }
  }
  loadEvent() {
    this.setData(this.triggerEls[0]);
  }
  clickEvent() {
    this.triggerEls.forEach((item) => {
      item.addEventListener('click', () => {
        this.triggerEls.forEach((item2) => { delete item2.dataset.state });
        item.dataset.state = 'active';
        this.setData(item);
      });
    });
  }
  setData(item) {
    this.priceEl.textContent = parseInt(item.dataset.price).toLocaleString();

    if ( item.dataset.urlStore?.trim() ) {
      this.storeEl.setAttribute('href', item.dataset.urlStore);
      delete this.storeEl.dataset.state;
    } else {
      this.storeEl.removeAttribute('href');
      this.storeEl.dataset.state = 'disable'
    }

    if ( item.dataset.urlAmazon?.trim() ) {
      this.amazonEl.setAttribute('href', item.dataset.urlAmazon);
      delete this.amazonEl.dataset.state;
    } else {
      this.amazonEl.removeAttribute('href');
      this.amazonEl.dataset.state = 'disable'
    }

    if ( item.dataset.urlRakuten?.trim() ) {
      this.rakutenEl.setAttribute('href', item.dataset.urlRakuten);
      delete this.rakutenEl.dataset.state;
    } else {
      this.rakutenEl.removeAttribute('href');
      this.rakutenEl.dataset.state = 'disable'
    }

    if ( item.dataset.urlYahoo?.trim() ) {
      this.yahooEl.setAttribute('href', item.dataset.urlYahoo);
      delete this.yahooEl.dataset.state;
    } else {
      this.yahooEl.removeAttribute('href');
      this.yahooEl.dataset.state = 'disable'
    }

    if ( item.dataset.urlAu?.trim() ) {
      this.auEl.setAttribute('href', item.dataset.urlAu);
      delete this.auEl.dataset.state;
    } else {
      this.auEl.removeAttribute('href');
      this.auEl.dataset.state = 'disable'
    }

  }
}

class AnchorLink {
  constructor(el, opt) {
    this.triggerEls = document.querySelectorAll('a[href^="#"]');
    if ( this.triggerEls.length ) {
      this.ignore = ['modal']; // data-*****
      this.gap    = {pc: 175, sp: 120}
      this.clickEvent();
    }
  }
  clickEvent() {
    this.triggerEls.forEach( (item) => {
      for ( let i = 0; i < this.ignore.length; i++ ) if ( this.ignore[i] in item.dataset ) return;
      item.addEventListener('click', (e) => {
        this.smooth(item);
        e.preventDefault();
      });
    });
  }
  smooth(trigger) {
    let target = document.getElementById( trigger.getAttribute('href').replace('#', '') );
    if (target === null) {
      console.log('Smooth [Not found ID element]\nPlease specify destination ID');
      return;
    }
    let pos = isMobile() ? window.pageYOffset + target.getBoundingClientRect().top - this.gap.sp : window.pageYOffset + target.getBoundingClientRect().top - this.gap.pc;
    gsap.to(window, .75, {
      scrollTo: {
        y: pos,
        autoKill: false
      },
      delay: 0,
      ease: Cubic.easeInOut
    });
  }
}

class Tab {
  constructor(el, opt) {
    this.groupEls = (el !== undefined) ? el.querySelectorAll('[data-tab="group"]') : document.querySelectorAll('[data-tab="group"]');
    if ( this.groupEls.length ) {
      this.triggerAttr = '[data-tab="trigger"]';
      this.contentAttr = '[data-tab="content"]';
      this.paramName = 'tab';
      this.elements = {
        trigger: [],
        content: []
      };
      this.setup();
    }
  }
  setup() {
    this.groupEls.forEach( (item, index) => {
      this.elements.trigger.push(item.querySelectorAll(this.triggerAttr));
      this.elements.content.push(item.querySelectorAll(this.contentAttr));
      let param = new URL(document.location).searchParams.get(this.paramName);
      if ( param === null ) {
        this.elements.trigger[index].forEach( (item2, index2) => {
          if ( index2 === 0 ) item2.dataset.state = 'active';
        });
        this.elements.content[index].forEach( (item3, index3) => {
          if ( index3 > 0 ) item3.style.display = 'none';
        });
      } else {
        this.elements.trigger[index].forEach( (item2, index2) => {
          if ( index2 === (parseInt(param)-1) ) item2.dataset.state = 'active';
        });
        this.elements.content[index].forEach( (item3, index3) => {
          item3.style.display = 'none';
          if ( index3 === (parseInt(param)-1) ) item3.style.display = 'block';
        });
      }
      this.clickEvent(index);
    });
  }
  clickEvent(index) {
    this.elements.trigger[index].forEach( (item) => {
      item.addEventListener('click', (e) => {

        this.elements.trigger[index].forEach( (item2) => {
          delete item2.dataset.state;
        });
        this.elements.content[index].forEach( (item3, index3) => {
          item3.style.display = 'none';
        });

        let pointer = [...this.elements.trigger[index]].indexOf(e.currentTarget);
        this.elements.trigger[index][pointer].dataset.state = 'active';
        this.elements.content[index][pointer].style.display = 'block';
      });
    });
  }
}


class SliderProduct {
  constructor() {
    this.El = document.querySelector('[data-slide-gallery="el"]');
    if ( this.El !== null ) {
      this.fieldEl = this.El.querySelector('[data-slide-gallery="field"]');
      this.prevEl  = this.El.querySelector('[data-slide-gallery="prev"]');
      this.nextEl  = this.El.querySelector('[data-slide-gallery="next"]');
      this.fracEl  = this.El.querySelector('[data-slide-gallery="frac"]');
      this.capEl   = this.El.querySelector('[data-slide-gallery="cap"]');
      this.handler = null;
      this.setup();
    }
  }
  setup() {
    this.handler = new Swiper(this.fieldEl, {
      loop: true,
      speed: 750,
      pagination: {
        el: this.fracEl,
        type: 'fraction'
      },
      navigation: {
        prevEl: this.prevEl,
        nextEl: this.nextEl
      },
      on: {
        slideChangeTransitionStart: () => {
          gsap.to(this.capEl, {
            autoAlpha: 0,
            duration: .125,
            ease: Linear.easeNone
          });
        },
        slideChangeTransitionEnd: (swiper) => {
          if ( swiper.slides[swiper.activeIndex].dataset.text !== undefined ) {
            gsap.to(this.capEl, {
              autoAlpha: 1,
              duration: .25,
              ease: Linear.easeNone,
              onStart: () => {
                this.capEl.textContent = swiper.slides[swiper.activeIndex].dataset.text;
              }
            });
          }
        }
      }
    });
  }
}

class SliderHomeKv {
  constructor() {
    this.El = document.querySelector('[data-slide-kv="el"]');
    if ( this.El !== null ) {
      this.fieldEl = this.El.querySelector('[data-slide-kv="field"]');
      this.handler = null;
      this.setup();
    }
  }
  setup() {
    this.handler = new Swiper(this.fieldEl, {
      loop: true,
      speed: 1000,
      parallax: true,
      //effect: 'fade',
      //allowTouchMove: false,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false
      },
      on: {
        beforeInit: (self) => {
          [...self.$wrapperEl[0].children].forEach((item) => {
            item.innerHTML = '<div class="swiper-slide-inner" data-swiper-parallax-x="100%">' + item.innerHTML + '</div>';
          });
        },
        init: (self) => {
          this.autoPlayObserve(self);
        }
      }
    });
  }
  autoPlayObserve(trgt) {
    trgt.autoplay.stop();
    if ( typeof ScrollTrigger.getById('slideKvObserve') === 'undefined' ) {
      ScrollTrigger.create({
        id: 'slideKvObserve',
        trigger: trgt.$el[0],
        start: 'top bottom',
        //markers: true,
        onEnter: () => {
          if ( trgt !== null ) trgt.autoplay.start();
        },
        onEnterBack: () => {
          if ( trgt !== null ) trgt.autoplay.start();
        },
        onLeave: () => {
          if ( trgt !== null ) trgt.autoplay.stop();
        },
        onLeaveBack: () => {
          if ( trgt !== null ) trgt.autoplay.stop();
        }
      });
    }
  }
}

class SliderHomeNew {
  constructor() {
    this.El = document.querySelector('[data-slide-new="el"]');
    if ( this.El !== null ) {
      this.fieldEl    = this.El.querySelector('[data-slide-new="field"]');
      this.prevEl     = this.El.querySelector('[data-slide-new="prev"]');
      this.nextEl     = this.El.querySelector('[data-slide-new="next"]');
      this.progressEl = this.El.querySelector('[data-slide-new="progress"]');
      this.handler = null;
      this.setup();
    }
  }
  setup() {
    this.handler = new Swiper(this.fieldEl, {
      slidesPerView: 'auto',
      loop: true,
      speed: 1000,
      autoplay: {
        delay: 3000,
        disableOnInteraction: false
      },
      navigation: {
        prevEl: this.prevEl,
        nextEl: this.nextEl
      },
      pagination: {
        el: this.progressEl,
        type: 'progressbar'
      },
      on: {
        init: (self) => {
          this.autoPlayObserve(self);
        }
      }
    });
  }
  autoPlayObserve(trgt) {
    trgt.autoplay.stop();
    if ( typeof ScrollTrigger.getById('slideNewObserve') === 'undefined' ) {
      ScrollTrigger.create({
        id: 'slideNewObserve',
        trigger: trgt.$el[0],
        start: 'top 90%',
        //markers: true,
        onEnter: () => {
          if ( trgt !== null ) trgt.autoplay.start();
        },
        onEnterBack: () => {
          if ( trgt !== null ) trgt.autoplay.start();
        },
        onLeave: () => {
          if ( trgt !== null ) trgt.autoplay.stop();
        },
        onLeaveBack: () => {
          if ( trgt !== null ) trgt.autoplay.stop();
        }
      });
    }
  }
}

class SliderHomeFeature {
  constructor() {
    this.El = document.querySelector('[data-slide-feature="el"]');
    if ( this.El !== null ) {
      this.fieldEl    = this.El.querySelector('[data-slide-feature="field"]');
      this.prevEl     = this.El.querySelector('[data-slide-feature="prev"]');
      this.nextEl     = this.El.querySelector('[data-slide-feature="next"]');
      this.progressEl = this.El.querySelector('[data-slide-feature="progress"]');
      this.handler = undefined;
      this.setup();
      this.resizeEvent();
    }
  }
  setup() {
    if ( isMobile() ) {
      this.handler = new Swiper(this.fieldEl, {
        slidesPerView: 'auto',
        loop: true,
        speed: 1000,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false
        },
        navigation: {
          prevEl: this.prevEl,
          nextEl: this.nextEl
        },
        pagination: {
          el: this.progressEl,
          type: 'progressbar'
        },
        on: {
          init: (self) => {
            this.autoPlayObserve(self);
          }
        }
      });
    } else {
      if ( typeof this.handler !== 'undefined' ) {
        this.handler.destroy(true, true);
        this.handler = undefined;
        ScrollTrigger.getById('slideFeatureObserve').kill();
      }
    }
  }
  autoPlayObserve(trgt) {
    trgt.autoplay.stop();
    if ( typeof ScrollTrigger.getById('slideFeatureObserve') === 'undefined' ) {
      ScrollTrigger.create({
        id: 'slideFeatureObserve',
        trigger: trgt.$el[0],
        start: 'top 90%',
        //markers: true,
        onEnter: () => {
          if ( trgt !== null ) trgt.autoplay.start();
        },
        onEnterBack: () => {
          if ( trgt !== null ) trgt.autoplay.start();
        },
        onLeave: () => {
          if ( trgt !== null ) trgt.autoplay.stop();
        },
        onLeaveBack: () => {
          if ( trgt !== null ) trgt.autoplay.stop();
        }
      });
    }
  }
  resizeEvent() {
    let vw = window.innerWidth;
    window.addEventListener('resize', () => {
      if (vw === window.innerWidth) return;
      vw = window.innerWidth;
      this.setup();
    });
  }
}

class SliderHomeCategory {
  constructor() {
    this.El = document.querySelector('[data-slide-cat="el"]');
    if ( this.El !== null ) {
      this.fieldEl    = this.El.querySelector('[data-slide-cat="field"]');
      this.prevEl     = this.El.querySelector('[data-slide-cat="prev"]');
      this.nextEl     = this.El.querySelector('[data-slide-cat="next"]');
      this.progressEl = this.El.querySelector('[data-slide-cat="progress"]');
      this.handler = null;
      this.setup();
    }
  }
  setup() {
    this.handler = new Swiper(this.fieldEl, {
      slidesPerView: 'auto',
      loop: true,
      speed: 1000,
      autoplay: {
        delay: 3000,
        disableOnInteraction: false
      },
      navigation: {
        prevEl: this.prevEl,
        nextEl: this.nextEl
      },
      pagination: {
        el: this.progressEl,
        type: 'progressbar'
      },
      on: {
        init: (self) => {
          this.autoPlayObserve(self);
        }
      }
    });
  }
  autoPlayObserve(trgt) {
    trgt.autoplay.stop();
    if ( typeof ScrollTrigger.getById('slideCatObserve') === 'undefined' ) {
      ScrollTrigger.create({
        id: 'slideCatObserve',
        trigger: trgt.$el[0],
        start: 'top 90%',
        //markers: true,
        onEnter: () => {
          if ( trgt !== null ) trgt.autoplay.start();
        },
        onEnterBack: () => {
          if ( trgt !== null ) trgt.autoplay.start();
        },
        onLeave: () => {
          if ( trgt !== null ) trgt.autoplay.stop();
        },
        onLeaveBack: () => {
          if ( trgt !== null ) trgt.autoplay.stop();
        }
      });
    }
  }
}

class SliderRepair {
  constructor() {
    this.Els = document.querySelectorAll('[data-slide-repair="el"]');
    if ( this.Els.length ) {
      this.fieldAttr     = '[data-slide-repair="field"]';
      this.prevAttr      = '[data-slide-repair="prev"]';
      this.nextAttr      = '[data-slide-repair="next"]';
      this.pagerAttr     = '[data-slide-repair="pager"]';
      this.handler   = [];
      this.setup();
    }
  }
  setup() {
    this.Els.forEach((item, index) => {
      let fieldEl     = item.querySelector(this.fieldAttr);
      let prevEl      = item.querySelector(this.prevAttr);
      let nextEl      = item.querySelector(this.nextAttr);
      let pagerEl     = item.querySelector(this.pagerAttr);

      this.handler[index] = new Swiper(fieldEl, {
        loop: true,
        speed: 1000,
        navigation: {
          prevEl: prevEl,
          nextEl: nextEl
        },
        pagination: {
          el: pagerEl,
          type: 'bullets',
          horizontalClass: 'swiper-add-pager',
          bulletClass: 'swiper-add-dot',
          bulletActiveClass: '-active',
          clickable: true
        }
      });
    });
  }
}


document.addEventListener("DOMContentLoaded", (event) => {
  const headerEvents    = new HeaderEvents();
  const footerEvents    = new FooterEvents();
  const publisherBtn    = new PublisherBtn();
  const modal           = new Modal();
  const collapse        = new Collapse();
  const collapseHistory = new CollapseHistory();
  const variant         = new Variant();
  const anchorLink      = new AnchorLink();
  const tab             = new Tab();
});

let sliderProduct = null;
window.addEventListener('load', (event) => {
  sliderProduct = new SliderProduct();
  const sliderHomeKv       = new SliderHomeKv();
  const sliderHomeNew      = new SliderHomeNew();
  const sliderHomeFeature  = new SliderHomeFeature();
  const sliderHomeCategory = new SliderHomeCategory();
  const sliderRepair       = new SliderRepair();
});
