class SliderHomeCategory {
  constructor() {
    this.El = document.querySelector('[data-slide-cat="el"]');
    if ( this.El !== null ) {
      this.fieldEl    = this.El.querySelector('[data-slide-cat="field"]');
      this.prevEl     = this.El.querySelector('[data-slide-cat="prev"]');
      this.nextEl     = this.El.querySelector('[data-slide-cat="next"]');
      this.progressEl = this.El.querySelector('[data-slide-cat="progress"]');
      this.handler = null;
      this.setup();
    }
  }
  setup() {
    this.handler = new Swiper(this.fieldEl, {
      slidesPerView: 'auto',
      loop: true,
      speed: 1000,
      autoplay: {
        delay: 3000,
        disableOnInteraction: false
      },
      navigation: {
        prevEl: this.prevEl,
        nextEl: this.nextEl
      },
      pagination: {
        el: this.progressEl,
        type: 'progressbar'
      },
      on: {
        init: (self) => {
          this.autoPlayObserve(self);
        }
      }
    });
  }
  autoPlayObserve(trgt) {
    trgt.autoplay.stop();
    if ( typeof ScrollTrigger.getById('slideCatObserve') === 'undefined' ) {
      ScrollTrigger.create({
        id: 'slideCatObserve',
        trigger: trgt.$el[0],
        start: 'top 90%',
        //markers: true,
        onEnter: () => {
          if ( trgt !== null ) trgt.autoplay.start();
        },
        onEnterBack: () => {
          if ( trgt !== null ) trgt.autoplay.start();
        },
        onLeave: () => {
          if ( trgt !== null ) trgt.autoplay.stop();
        },
        onLeaveBack: () => {
          if ( trgt !== null ) trgt.autoplay.stop();
        }
      });
    }
  }
}
