class Tab {
  constructor(el, opt) {
    this.groupEls = (el !== undefined) ? el.querySelectorAll('[data-tab="group"]') : document.querySelectorAll('[data-tab="group"]');
    if ( this.groupEls.length ) {
      this.triggerAttr = '[data-tab="trigger"]';
      this.contentAttr = '[data-tab="content"]';
      this.paramName = 'tab';
      this.elements = {
        trigger: [],
        content: []
      };
      this.setup();
    }
  }
  setup() {
    this.groupEls.forEach( (item, index) => {
      this.elements.trigger.push(item.querySelectorAll(this.triggerAttr));
      this.elements.content.push(item.querySelectorAll(this.contentAttr));
      let param = new URL(document.location).searchParams.get(this.paramName);
      if ( param === null ) {
        this.elements.trigger[index].forEach( (item2, index2) => {
          if ( index2 === 0 ) item2.dataset.state = 'active';
        });
        this.elements.content[index].forEach( (item3, index3) => {
          if ( index3 > 0 ) item3.style.display = 'none';
        });
      } else {
        this.elements.trigger[index].forEach( (item2, index2) => {
          if ( index2 === (parseInt(param)-1) ) item2.dataset.state = 'active';
        });
        this.elements.content[index].forEach( (item3, index3) => {
          item3.style.display = 'none';
          if ( index3 === (parseInt(param)-1) ) item3.style.display = 'block';
        });
      }
      this.clickEvent(index);
    });
  }
  clickEvent(index) {
    this.elements.trigger[index].forEach( (item) => {
      item.addEventListener('click', (e) => {

        this.elements.trigger[index].forEach( (item2) => {
          delete item2.dataset.state;
        });
        this.elements.content[index].forEach( (item3, index3) => {
          item3.style.display = 'none';
        });

        let pointer = [...this.elements.trigger[index]].indexOf(e.currentTarget);
        this.elements.trigger[index][pointer].dataset.state = 'active';
        this.elements.content[index][pointer].style.display = 'block';
      });
    });
  }
}
