class Collapse {
  constructor() {
    this.triggerEls = document.querySelectorAll('[data-collapse="trigger"]');
    if ( this.triggerEls.length ) {
      this.clickEvent();
    }
  }
  clickEvent() {
    this.triggerEls.forEach((item) => {
      item.addEventListener('click', (e) => {
        this.slideCollapse(item);
        e.preventDefault();
      });
    });
  }
  slideCollapse(triggerEl) {
    let targetEl = (triggerEl.getAttribute('href') === null) ? triggerEl.nextElementSibling : document.querySelector(triggerEl.getAttribute('href'));
    if ( triggerEl.dataset.state === undefined ) {
      gsap.to(targetEl, {
        height: 'auto',
        opacity: 1,
        duration: .5,
        ease: Power2.easeInOut,
        onStart: () => {
          triggerEl.dataset.state = 'open';
        }
      });
    } else {
      gsap.to(targetEl, {
        height: 0,
        opacity: 0,
        duration: .5,
        ease: Power2.easeInOut,
        clearProps: true,
        onStart: () => {
          delete triggerEl.dataset.state;
        }
      });
    }
  }
}

class CollapseHistory {
  constructor() {
    this.triggerEl = document.querySelector('[data-collapse="history"]');
    if ( this.triggerEl !== null ) {
      this.targetEl  = this.triggerEl.previousElementSibling;
      this.defHeight = this.targetEl.clientHeight;
      this.defText   = this.triggerEl.textContent;
      this.clickEvent();
    }
  }
  clickEvent() {
    this.triggerEl.addEventListener('click', (e) => {
      this.slideCollapse();
      e.preventDefault();
    });
  }
  slideCollapse() {
    if ( this.targetEl.dataset.state === undefined ) {
      gsap.to(this.targetEl, {
        height: 'auto',
        duration: .5,
        ease: Power2.easeInOut,
        onStart: () => {
          this.defHeight = parseInt(getComputedStyle(this.targetEl).height);
          this.triggerEl.textContent = 'CLOSE';
          this.targetEl.dataset.state = 'open';
        }
      });
    } else {
      gsap.to(this.targetEl, {
        height: this.defHeight,
        duration: .5,
        ease: Power2.easeInOut,
        clearProps: true,
        onStart: () => {
          this.triggerEl.textContent = this.defText;
          delete this.targetEl.dataset.state;
        }
      });
    }
  }

}
